import {MetricCard} from 'components/MetricCard';
import gql from 'graphql-tag';
import React, {useState} from 'react';
import {Button, Col, Modal, Row} from 'react-bootstrap';
import {GqlQueryRender} from 'shared/gql/gqlQueryRender';
import {AdminRoutes} from 'shared/Routes';
import {
    FeedPostCategory,
    useAdminMetricsQuery,
    useImportNotificationsMutation
} from 'types/gqlReactTypings.generated.d';
import {PageHeader} from '../../components/PageHeader';
import AppInput, {InputType} from "../../components/AppInput";
import {FileDropzone} from "../../components/fileDropzone";

gql`
    query AdminMetrics {
        adminMetrics {
            userCount, feedPostCount, teamCount, liveStreamCount
        }
    }
`

export const AdminDashboard: React.FC = () => {
    const adminMetricsQuery = useAdminMetricsQuery();
    const [showImportModal, setShowImportModal] = useState(false);
    const [file, setFile] = useState<string | undefined>();
    const [importMutation] = useImportNotificationsMutation();
    const [errorMessage, setErrorMessage] = useState<string | undefined>();
    const [successMessage, setSuccessMessage] = useState<string | undefined>();
    const importNotifications = async () => {
        const res = await importMutation({variables: {fileName: file}});
        if (res.errors) {
            setErrorMessage(res.errors.map((e) => e.message).join("\n"));
        } else {
            setSuccessMessage(res.data?.importNotifications.message);
            // setShowImportModal(false);
            // alert(res.data?.importNotifications.message);
        }
    }
    return (
        <div>
            <Modal show={showImportModal} onHide={() => {
                setErrorMessage(undefined);
                setFile(undefined);
                setSuccessMessage(undefined);
                setShowImportModal(false);
            }}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Imprt Notifications</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row style={{marginBottom: 20}}>
                        <Col md={12}>
                            {successMessage && <h3 style={{color: 'green'}}>{successMessage}</h3>}
                            {errorMessage && <h3 style={{color: 'red'}}>{errorMessage}</h3>}
                        </Col>
                        {!successMessage && <>
                            <Col md={12}>
                                <FileDropzone
                                    onSuccess={(file, fileName, fileKey) => {
                                        setFile(fileName)
                                    }}
                                    accept={{'text/csv': []}}
                                />
                            </Col>
                            <Col md={12} style={{marginTop: 20}}>
                                <div>{file}</div>
                            </Col>
                        </>}
                    </Row>

                </Modal.Body>
                <Modal.Footer>
                    {!successMessage && <Button variant='success' onClick={importNotifications}>Import</Button>}
                </Modal.Footer>
            </Modal>
            <PageHeader
                title='Dashboard'
                rightItem={<Button variant="primary" onClick={() => setShowImportModal(true)}>Import
                    Notifications</Button>}
            />

            <GqlQueryRender query={adminMetricsQuery as any}>
                {({
                      adminMetrics: {userCount, feedPostCount, teamCount, liveStreamCount},
                  }: any) => {

                    return (
                        <div>
                            <Row>
                                <MetricCard color='success' faIcon='user' label='App Users' value={userCount}
                                            to={AdminRoutes.USERS}/>
                                <MetricCard color='primary' faIcon='video' label='Feed Posts' value={feedPostCount}
                                            to={AdminRoutes.FEED_POSTS}/>
                                <MetricCard color='info' faIcon='magnet' label='Teams' value={teamCount}
                                            to={AdminRoutes.TEAMS}/>
                                <MetricCard color='warning' faIcon='stream' label='Live Streams' value={liveStreamCount}
                                            to={AdminRoutes.LIVE_STREAMS}/>

                            </Row>
                        </div>
                    );
                }}
            </GqlQueryRender>
        </div>
    );
}
