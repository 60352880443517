import React, {useState, useEffect} from 'react';
import 'styles/css/joinTeam.css'
import gql from 'graphql-tag';
import {AttendanceStatus, useAddEventConfirmationMutation} from "../../types/gqlReactTypings.generated.d";
import {FetchResult} from "@apollo/client";
import {TemplateHeader} from "./components/TemplateHeader";
import {TemplateFooter} from "./components/TemplateFooter";

gql`
    mutation AddEventConfirmation($input: EventConfirmationInput!) {
        addEventConfirmation(input: $input) {
            id
            userId
            eventId
            createdAt
            updatedAt
            going
            status
        }
    }
`

export const TeamEvent = () => {
    const errorString = 'Something is wrong!';
    const successString = 'Your response saved';
    const queryParameters = new URLSearchParams(window.location.search)
    const value = queryParameters.get("value") as AttendanceStatus;
    const userId = queryParameters.get("key");
    const eventId = queryParameters.get("eventId");
    const link = queryParameters.get("link");
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [AddEventConfirmation, {data, error, loading}] = useAddEventConfirmationMutation();
    const [userName, setUserName] = useState('');

    useEffect(() => {
        AddEventConfirmation({
            variables: {
                input: {
                    userId: userId ?? '',
                    eventId: eventId ?? '',
                    status: value,
                    source: 'web',
                }
            }
        })
            .then((res: FetchResult) => {
                let message = 'Thanks for your reply.';
                const status = res.data?.addEventConfirmation?.status;
                if(status == AttendanceStatus.Going){
                    message = 'Thanks for your reply. See you at the event.'
                }else if(status == AttendanceStatus.NotGoing){
                    message = 'Your team will miss you.'
                }else if(status == AttendanceStatus.Maybe){
                    message = "When you decide, don't forget to let the team know."
                }
                setSuccessMessage(message);
                // setUserName(response.data?.addEventConfirmation.user.firstName)
                if (link) {
                    window.location.replace(link)
                }
            })
            .catch((error: Error) => {
                setErrorMessage(errorString);
            });
    }, [AddEventConfirmation]);
    return (
        <div className="container">

            <TemplateHeader/>
            <div className="content">
                <table
                    cellPadding={0}
                    cellSpacing={0}
                    role="presentation"
                    style={{width: "100%", maxWidth: 600}}
                >
                    <tbody>
                    <tr>
                        <td
                            style={{
                                direction: "ltr",
                                fontSize: 0,
                                padding: "0px 0px 0px 0px",
                                textAlign: "center"
                            }}
                        >
                            <div
                                className="mj-column-per-100 mj-outlook-group-fix"
                                style={{
                                    fontSize: 0,
                                    textAlign: "center",
                                    direction: "ltr",
                                    display: "inline-block",
                                    verticalAlign: "top",
                                    width: "100%"
                                }}
                            >
                                <table
                                    cellPadding={0}
                                    cellSpacing={0}
                                    role="presentation"
                                    style={{verticalAlign: "top"}}
                                    width="100%"
                                >
                                    <tbody>
                                    {/* CONTENT BLOCK */}
                                    <tr>
                                        <td
                                            style={{
                                                background: "transparent",
                                                fontSize: 0,
                                                padding: "5px 25px 5px 25px",
                                                paddingTop: 5,
                                                paddingBottom: 5,
                                                wordBreak: "break-word"
                                            }}
                                        >
                                            <div
                                                style={{
                                                    fontFamily: "Arial, sans-serif",
                                                    fontSize: 13,
                                                    letterSpacing: "normal",
                                                    lineHeight: 1,
                                                    textAlign: "center",
                                                    color: "#000000"
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        display: "inline-block",
                                                        background: errorMessage ? '#FFA500FF' : '#4a90e2',
                                                        color: "#ffffff",
                                                        padding: "10px 20px",
                                                        fontSize: "1em",
                                                        fontWeight: "bold",
                                                        borderRadius: 5
                                                    }}
                                                >
                                                    {errorMessage ? errorMessage : successMessage}
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    {/* END CONTENT BLOCK */}
                                    {/* CONTENT BLOCK */}
                                    <tr>
                                        <td
                                            style={{
                                                background: "transparent",
                                                // fontSize: 0,
                                                // padding: "25px 25px 5px 25px",
                                                paddingTop: 25,
                                                paddingBottom: 25,
                                                wordBreak: "break-word"
                                            }}
                                        >
                                            <div
                                                style={{
                                                    fontFamily: "Arial, sans-serif",
                                                    fontSize: 15,
                                                    letterSpacing: "normal",
                                                    lineHeight: 1,
                                                    textAlign: "center",
                                                    color: "#000000"
                                                }}
                                            >
                                                {/*<a*/}
                                                {/*    href="https://calendar.google.com/calendar/render?action=TEMPLATE&text=Ultrain+Practice&details=Join+us+for+a+great+practice+session&location=Ultrain+Stadium&dates=20241128T150000Z/20241128T160000Z"*/}
                                                {/*    target="_blank"*/}
                                                {/*    rel="noopener noreferrer"*/}
                                                {/*    style={{*/}
                                                {/*        display: "inline-block",*/}
                                                {/*        textDecoration: "none",*/}
                                                {/*        color: "#4a4a4a",*/}
                                                {/*        fontSize: "1em",*/}
                                                {/*    }}*/}
                                                {/*>*/}
                                                {/*    <span style={{marginRight: 10}}>📅</span> Add to your Calendar*/}
                                                {/*</a>*/}
                                            </div>
                                        </td>
                                    </tr>
                                    {/* END CONTENT BLOCK */}
                                    </tbody>
                                </table>
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <TemplateFooter/>
        </div>

    );

}
